var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("Mortgage Request")]),_vm._v(" "+_vm._s(_vm.test2)+" "),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestMortgage.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.mortgage.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},on:{"change":function($event){return _vm.checkPayment(_vm.mortgage.unit_id)}},model:{value:(_vm.mortgage.unit_id),callback:function ($$v) {_vm.$set(_vm.mortgage, "unit_id", $$v)},expression:"mortgage.unit_id"}}),(_vm.submitted && _vm.$v.mortgage.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),(_vm.mortgage.unit_id && _vm.payment == 2)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Type*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.mortgage.mortgage_type.$error,
                        },attrs:{"options":_vm.mortgageOptions,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.mortgage.mortgage_type),callback:function ($$v) {_vm.$set(_vm.mortgage, "mortgage_type", $$v)},expression:"mortgage.mortgage_type"}}),(_vm.submitted && _vm.$v.mortgage.mortgage_type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.mortgage_type.required)?_c('span',[_vm._v("Choose a value")]):_vm._e()]):_vm._e()],1)]):_vm._e(),(_vm.mortgage.unit_id && _vm.payment == 3)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Type*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.mortgage.mortgage_type.$error,
                        },attrs:{"options":_vm.installmentOption,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.mortgage.mortgage_type),callback:function ($$v) {_vm.$set(_vm.mortgage, "mortgage_type", $$v)},expression:"mortgage.mortgage_type"}}),(_vm.submitted && _vm.$v.mortgage.mortgage_type.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.mortgage_type.required)?_c('span',[_vm._v("Choose a value")]):_vm._e()]):_vm._e()],1)]):_vm._e()]),(_vm.mortgage.unit_id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mortgage.bank_name),expression:"mortgage.bank_name"}],staticClass:"form-control mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.mortgage.bank_name.$error,
                        },attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.mortgage.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mortgage, "bank_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.mortgage.bank_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.bank_name.required)?_c('span',[_vm._v("Bank name required")]):_vm._e()]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"row"},[(_vm.payment == 2)?_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Release Letter*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.mortgage.release_letter.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleReleaseLetter}}),(_vm.submitted && _vm.$v.mortgage.release_letter.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.release_letter.required)?_c('span',[_vm._v("Release letter is required")]):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.payment == 2 || _vm.payment == 3)?_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Offer Letter*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.mortgage.offer_letter.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleOfferLetter}}),(_vm.submitted && _vm.$v.mortgage.offer_letter.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.mortgage.offer_letter.required)?_c('span',[_vm._v("Offer letter is required")]):_vm._e()]):_vm._e()],1):_vm._e()]),_c('submitButton')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }