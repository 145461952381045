<template>
    <Layout>
        <h4>Mortgage Request</h4>
        {{ test2 }}
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestMortgage"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="mortgage.unit_id"
                            :options="mappedUserUnits"
                            @change="checkPayment(mortgage.unit_id)"
                            :class="{
                                'is-invalid':
                                    submitted && $v.mortgage.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.mortgage.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.mortgage.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6" v-if="mortgage.unit_id && payment == 2">
                    <div class="form-group">
                        <label for="ownerfname">Type*</label>
                        <b-form-radio-group
                            v-model="mortgage.mortgage_type"
                            :options="mortgageOptions"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.mortgage.mortgage_type.$error,
                            }"
                        ></b-form-radio-group>

                        <div
                            v-if="submitted && $v.mortgage.mortgage_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.mortgage.mortgage_type.required"
                                >Choose a value</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" v-if="mortgage.unit_id && payment == 3">
                    <div class="form-group">
                        <label for="ownerfname">Type*</label>
                        <b-form-radio-group
                            v-model="mortgage.mortgage_type"
                            :options="installmentOption"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.mortgage.mortgage_type.$error,
                            }"
                        ></b-form-radio-group>

                        <div
                            v-if="submitted && $v.mortgage.mortgage_type.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.mortgage.mortgage_type.required"
                                >Choose a value</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="mortgage.unit_id">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Bank Name*</label>
                        <input
                            v-model="mortgage.bank_name"
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted && $v.mortgage.bank_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.mortgage.bank_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.mortgage.bank_name.required"
                                >Bank name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6" v-if="payment == 2">
                    <label for="ownerfname">Release Letter*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.mortgage.release_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.mortgage.release_letter.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.mortgage.release_letter.required"
                            >Release letter is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6" v-if="payment == 2 || payment == 3">
                    <label for="ownerfname">Offer Letter*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleOfferLetter"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.mortgage.offer_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.mortgage.offer_letter.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.mortgage.offer_letter.required"
                            >Offer letter is required</span
                        >
                    </div>
                </div>
            </div>

            <!-- <div class="mt-3 mb-3">
                <button type="submit" class="btn btn-primary btn-lg">
                    Submit
                </button>
            </div> -->
            <submitButton />
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL } from "../../common";
import axios from "axios";
import { required, requiredIf } from "vuelidate/lib/validators";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    validations: {
        mortgage: {
            unit_id: { required },
            bank_name: { required },
            mortgage_type: { required },
            offer_letter: {
                required: requiredIf(function () {
                    return this.payment == 2 || 3;
                }),
            },
            release_letter: {
                required: requiredIf(function () {
                    return this.payment == 2;
                }),
            },
        },
    },
    data() {
        return {
            mortgageOptions: [
                { item: "Title Deed", name: "Title Deed" },
                { item: "Interim", name: "Interim" },
            ],
            installmentOption: [{ item: "Interim", name: "Interim" }],
            submitted: false,
            fullPage: false,
            mortgage: {
                unit_id: "",
                bank_name: "",
                offer_letter: "",
                offer_letter_filename: "",
                release_letter: "",
                release_letter_filename: "",
                mortgage_type: "",
            },
            payment: "",
        };
    },

    components: {
        Layout,
        submitButton,
    },
    mounted() {
        console.log("user units", this.test);
    },

    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
    methods: {
        requestMortgage() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log("this is a valid form");
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}mortgages?token=${localStorage.cs_user_token}`,
                            this.mortgage
                        )
                        .then((res) => {
                            console.log(res.data.response);
                            this.successMsg(
                                "Your request has been received by the Customer Service Team",
                                "Reference Number" +
                                    " " +
                                    res.data.response.ref_no
                            );
                            this.resetFields();
                            loader.hide();
                            this.$router.push("/");
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },

        checkPayment(id) {
            console.log(id);
            let unit = this.mappedUserUnits.filter((ele) => ele.value == id);
            console.log(unit);
            this.payment = unit[0].payment;
            if (unit[0].payment == 1) {
                this.showError(
                    "You cannot apply for mortgage for a unit under full payment "
                );
                this.mortgage.unit_id = "";
            }
        },

        handleOfferLetter(e) {
            let reader = new FileReader();
            this.mortgage.offer_letter_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.mortgage.offer_letter = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleReleaseLetter(e) {
            let reader = new FileReader();
            this.mortgage.release_letter_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.mortgage.release_letter = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
    },
};
</script>
<style lang="scss" scoped>
.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
